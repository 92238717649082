import { StyledActionsContainer, StyledForm } from "./StyleComponents";
import { EditFormProps } from "./EditFormProps";
import { useEditForm } from "./useEditForm";
import { EditFormContents } from "./EditFormContents";
import { translate } from "@kaltura/mediaspace-shared-utils";
import { Button } from "@kaltura/ds-react-components";

/**
 * Entry edit form
 */
export const EditForm = (props: EditFormProps) => {
    const editFormHookProps = useEditForm(props);
    const { processing, canSaveForm, handleSubmitForm, handleCancel } = editFormHookProps;

    return (
        <StyledForm onSubmit={handleSubmitForm}>
            <EditFormContents {...props} {...editFormHookProps} />
            <StyledActionsContainer>
                <Button
                    onClick={handleCancel}
                    disabled={processing}
                    size={"medium"}
                    variant={"borderless"}
                    color={"secondary"}
                >
                    {translate("Cancel")}
                </Button>
                <Button type="submit" loading={processing} disabled={!canSaveForm} size={"medium"} variant={"pill"}>
                    {translate("Save")}
                </Button>
            </StyledActionsContainer>
        </StyledForm>
    );
};

export default EditForm;
