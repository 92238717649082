import styled from "@emotion/styled";
import { useMemo } from "react";
import { composeClasses } from "@kaltura/mediaspace-shared-styled";
import { SimpleSiteHeaderProps } from "./SimpleSiteHeaderProps";
import {
    HeaderLogo,
    LocaleMenu,
    HeaderMenuScrollContext,
    HeaderMenuScrollContextType,
} from "@kaltura/mediaspace-shared-layout";
import { getSiteHeaderClass } from "./simpleSiteHeaderClasses";

const useUtilityClasses = (styleProps: Partial<SimpleSiteHeaderProps>) => {
    const { classes } = styleProps;

    const slots = {
        hero: ["hero"],
        content: ["content"],
        root: ["root"],
        logo: ["logo"],
        title: ["title"],
        language: ["language"],
    };

    return composeClasses(slots, getSiteHeaderClass, classes);
};

const SpaceFillerDiv = styled.div`
    flex-grow: 2;
`;

const StyledLogoWrapper = styled.div`
    height: 36px;
    position: relative;
`;

const LocaleWrapper = styled.div(({ theme }) => ({
    position: "absolute",
    right: 0,
    top: 0,
    display: "flex",
    width: "100%",
    height: "56px",
    padding: "12px 16px",
    justifyContent: "flex-end",
}));
const StyledTextWrapper = styled.div(({ theme }) => ({
    fontSize: 18,
    marginTop: theme.spacing(2),
    color: theme.kaltura.palette.translucent.light,
    position: "relative",
}));

const ContainerOverlay = styled.div(({ theme }) => ({
    background: "linear-gradient(to right, rgba(28,28,28,0.8) 0%,rgba(28,28,28,0) 50%,rgba(19,19,19,0) 80%)",
    inset: 0,
    position: "absolute",
    pointerEvents: "none",
    height: "160px",
}));

const LocaleOverlay = styled.div(({ theme }) => ({
    background: "linear-gradient(to bottom, rgba(19,19,19,0.8) 0%,rgba(76,76,76,0) 80%)",
    inset: 0,
    position: "absolute",
    pointerEvents: "none",
}));

const SystemWidth = styled.div(({ theme }) => ({
    display: "flex",
    alignItems: "center",
}));

interface StyledSiteHeaderProps {
    backgroundImage?: string;
}

const StyledSiteHeader = styled.div<StyledSiteHeaderProps>(({ theme, backgroundImage }) => ({
    height: "160px",
    padding: theme.spacing(5.125, 4, 5.125, 4),
    backgroundColor: theme.kaltura.palette.primary.main,
    ...(
        backgroundImage && {
            backgroundSize: "cover",
            backgroundImage: `url("${backgroundImage}")`,
            backgroundPosition: "center center",
        }
    ),
}));

/**
 * Site-header to be used in registration and login related pages instead of the standard site-header.
 * Don't include navigation, user menus, etc.
 * @param props
 * @constructor
 */
export function SimpleSiteHeader(props: SimpleSiteHeaderProps) {
    const classes = useUtilityClasses(props);

    // header menu scroll context value
    const contextValue = useMemo<HeaderMenuScrollContextType>(
        () => ({
            headerColor: props.backgroundImage ? "transparent" : "light",
            scrollRatio: 0,
        }),
        [props.backgroundImage]
    );

    return (
        <HeaderMenuScrollContext.Provider value={contextValue}>
            <StyledSiteHeader className={classes.root} backgroundImage={props.backgroundImage}>
                <SystemWidth className={classes.hero}>
                    <SpaceFillerDiv className={classes.content}>
                        <ContainerOverlay />
                        {props.logoProps && (
                            <StyledLogoWrapper className={classes.logo}>
                                <HeaderLogo minimize={false} {...props.logoProps} />
                            </StyledLogoWrapper>
                        )}
                        {props.headerText && (
                            <StyledTextWrapper className={classes.title}>{props.headerText}</StyledTextWrapper>
                        )}
                    </SpaceFillerDiv>
                    <LocaleWrapper className={classes.language}>
                        {props.locales && props.locales.length > 0 && props.currentLocale && (
                            <>
                                <LocaleOverlay />
                                <LocaleMenu locales={props.locales} currentLocale={props.currentLocale} />
                            </>
                        )}
                    </LocaleWrapper>
                </SystemWidth>
            </StyledSiteHeader>
        </HeaderMenuScrollContext.Provider>
    );
}

export default SimpleSiteHeader;
