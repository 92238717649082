import { styled } from "@mui/material";
import { StatusLive, TruncateWithTooltip } from "@kaltura/mediaspace-shared-ui";
import { Typography } from "@kaltura/ds-react-components";
import { HtmlReactParser, translate } from "@kaltura/mediaspace-shared-utils";
import { Skeleton } from "@kaltura/mediaspace-shared-styled";
import React, { forwardRef } from "react";

export interface UserNameProps {
    className?: string;
    name: string;
    size?: "big" | "small";
    isLive?: boolean;
    id?: string;
    lines?: number | "unlimited";
}

export const UserName = forwardRef<HTMLDivElement, UserNameProps>(
    ({ className, name, size = "small", isLive, id, lines = 1 }, ref) => {
        const parsedName = HtmlReactParser(name);
        return (
            <StyledName
                variant={size === "big" ? "h2" : "h4"}
                component={"div"}
                className={className}
                id={id}
                ref={ref}
            >
                {lines !== "unlimited" && (
                    <TruncateWithTooltip text={parsedName} lines={lines}>
                        <div>{parsedName}</div>
                    </TruncateWithTooltip>
                )}
                {lines === "unlimited" && <div>{parsedName}</div>}
                {isLive && <StatusLive>{translate("Live")}</StatusLive>}
            </StyledName>
        );
    }
);

const StyledName = styled(Typography)(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "baseline",
    whiteSpace: "normal",
    gap: theme.spacing(0.5),
    color: theme.kaltura.palette.tone1,
    margin: 0,
}));

export const UserNameSkeleton = styled(Skeleton)(({ theme }) => ({
    height: theme.spacing(3),
    borderRadius: theme.kaltura.shape.roundness1,
}));
