import React, { useState } from "react";
import { PublishStatus } from "@kaltura/mediaspace-shared-types";
import { Button, ModalTitle } from "@kaltura/ds-react-components";
import { translate } from "@kaltura/mediaspace-shared-utils";
import { MediaCategoriesContainer } from "../../../media-categories/MediaCategoriesContainer";
import { ButtonClickAnalyticsType } from "@kaltura/mediaspace-shared-types";
import { useButtonAnalytics } from "@kaltura/mediaspace-shared-hooks";
// noinspection ES6PreferShortImport
import { CategoryLinkAnalyticsContext } from "../../../media-categories/CategoryLinkAnalyticsContext";
import {
    ModalProps,
    StyledModal,
    StyledModalActions,
    StyledModalContent,
    StyledModalDescription,
} from "../StyledModal";

/**
 * Publish menu (set as-)Unlisted selection modal
 */
export function UnlistedModal(props: ModalProps) {
    const { onClose, onSubmit, entryIds, publishStatus, isBulk } = props;

    const sendButtonAnalytics = useButtonAnalytics();

    const [processing, setProcessing] = useState<boolean>(false);

    // current publishStatus is not relevant for bulk publish
    const isPrivate = publishStatus === PublishStatus.PRIVATE;
    const isPublished = publishStatus === PublishStatus.PUBLISHED;

    const entryId = entryIds.length === 1 ? entryIds[0] : undefined;

    const handleCancel = () => {
        isBulk
            && sendButtonAnalytics(
                "Publish - Set to unlisted modal box (bulk) - cancel",
                ButtonClickAnalyticsType.CLOSE,
                entryId
            );
        isPrivate
            && sendButtonAnalytics(
                "Publish - Set private to unlisted modal box - cancel",
                ButtonClickAnalyticsType.CLOSE,
                entryId
            );
        isPublished
            && sendButtonAnalytics(
                "Publish - Set published to unlisted modal box - cancel",
                ButtonClickAnalyticsType.CLOSE,
                entryId
            );
        onClose?.();
    };

    const handleSubmit = () => {
        isBulk
            && sendButtonAnalytics(
                "Publish - Set to unlisted modal box (bulk) - save",
                ButtonClickAnalyticsType.SAVE,
                entryId
            );
        isPrivate
            && sendButtonAnalytics(
                "Publish - Set private to unlisted modal box - save",
                ButtonClickAnalyticsType.SAVE,
                entryId
            );
        isPublished
            && sendButtonAnalytics(
                "Publish - Set published to unlisted modal box - save",
                ButtonClickAnalyticsType.SAVE,
                entryId
            );

        setProcessing(true);
        onSubmit?.({ entryIds: entryIds, state: PublishStatus.UNLISTED });
    };

    const handleCategoryLinkAnalytics = () => {
        // category links are not shown in bulk publish
        isPrivate
            && sendButtonAnalytics(
                "Publish - Set private to unlisted modal box - Link to category",
                ButtonClickAnalyticsType.LINK,
                entryId
            );
        isPublished
            && sendButtonAnalytics(
                "Publish - Set published to unlisted modal box - Link to category",
                ButtonClickAnalyticsType.LINK,
                entryId
            );
    };

    return (
        <StyledModal
            open={true}
            className="kms-ds-publish-modal-unlisted"
            onMouseLeave={(event) => event.stopPropagation()}
        >
            <ModalTitle>{translate("Set media as unlisted")}</ModalTitle>
            <StyledModalContent>
                {isBulk && (
                    <StyledModalDescription>
                        {translate(
                            "Are you sure you want to set %1 media items as unlisted? They will be available to any user with a link.",
                            [entryIds.length]
                        )}
                    </StyledModalDescription>
                )}
                {isPrivate && (
                    <StyledModalDescription>
                        {translate(
                            "Are you sure you want to set this media as unlisted? It will be available to any user with a link."
                        )}
                    </StyledModalDescription>
                )}
                {isPublished && (
                    <>
                        <StyledModalDescription>
                            {translate(
                                "Are you sure you want to set this media as unlisted? It will be removed from all published locations in this application, but will be available for any users with a link to the media."
                            )}
                        </StyledModalDescription>
                        <CategoryLinkAnalyticsContext.Provider
                            value={{
                                sendCategoryLinkAnalytics: handleCategoryLinkAnalytics,
                                sendCategoryBubbleAnalytics: () => {},
                            }}
                        >
                            {entryId && <MediaCategoriesContainer entryId={entryId} />}
                        </CategoryLinkAnalyticsContext.Provider>
                    </>
                )}
            </StyledModalContent>

            <StyledModalActions>
                <Button variant={"borderless"} color={"secondary"} onClick={handleCancel} disabled={processing}>
                    {translate("Cancel")}
                </Button>
                <Button onClick={handleSubmit} loading={processing} disabled={processing} size={"medium"}>
                    {translate("Save")}
                </Button>
            </StyledModalActions>
        </StyledModal>
    );
}
