import { baseUrl } from "@kaltura/mediaspace-shared-utils";
import UnisphereWidget from "@kaltura/mediaspace-shared-unisphere-widget";
import { useIsMobileSidebarLayout } from "@kaltura/ds-react-layouts";
import { useMemo } from "react";

// Enum to define the types of ContentLab widgets
export enum ContentLabWidgetType {
    NONE = "", // when used content lab will render the menu with available features
    CLIPS = "clips", // when selected create clips feature will be automatically selected
}

export interface ContentLabCtaProps {
    // the partner id
    partnerId: string;
    // the entry id for which clips will be generated
    // for VOD entries will be initialized with the entry id
    // for live entries will be initialized with the recordingID
    entryId: string;
    // the entry context id - for all entry types (live or vod) it will be initialized with entry id
    sessionContextId: string;
    // uiConf id for the preview player inside the content lab
    playerId: string;
    // kaltura server url
    serverUrl: string;
    // uses user ks
    ks: string;
    appName: string;
    appVersion: string;
    buttonLabel?: string;
    // analytics server uri
    analyticsServerURI: string;
}

export const ContentLabCta = ({
    ks,
    partnerId,
    playerId,
    appName,
    appVersion,
    serverUrl,
    entryId,
    sessionContextId,
    analyticsServerURI,
    buttonLabel = "",
}: ContentLabCtaProps) => {
    const contentLabSettings = useMemo(
        () => ({
            ks,
            pid: partnerId,
            uiconfId: playerId,
            analyticsServerURI,
            hostAppName: appName,
            hostAppVersion: appVersion,
            kalturaServerURI: serverUrl,
            kalturaServerProxyURI: `${baseUrl}/contentlab/api`,
            clipsOverride: "",
            entryId,
            buttonLabel,
            eventSessionContextId: sessionContextId,
            widget: ContentLabWidgetType.CLIPS,
        }),
        [
            ks,
            partnerId,
            playerId,
            appName,
            appVersion,
            serverUrl,
            entryId,
            sessionContextId,
            analyticsServerURI,
            buttonLabel,
        ]
    );

    const isMobile = useIsMobileSidebarLayout();
    // Render the ContentLab widget component only on desktop layout
    return (
        !isMobile && (
            <UnisphereWidget
                widgetName="unisphere.module.content-lab"
                context="application"
                settings={contentLabSettings}
            />
        )
    );
};

export default ContentLabCta;
